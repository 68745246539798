// Main JS File
import 'style/style.scss'
import 'core-js/stable'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { sync } from 'vuex-router-sync'
import { languages } from 'api/config'
import api from 'api'
import router from './router'
import store from './store'
import './partial/webfonts'
import './partial/detect-browser'
import App from './App'
import './registerServiceWorker'
// Vue Plugins
import VueCookie from 'vue-cookie'
import VueLogger from 'vue-logger'
import { init } from './plugins/element-ui'
import '@mdi/font/css/materialdesignicons.css'
// import VueTextareaAutosize from 'vue-textarea-autosize'

/**
 * Filters
 */
import './shared/filters'

// OfflinePluginRuntime.install()

// keep vue-router and vuex store in sync
sync(store, router)

init(Vue)
// Vue.use(VueTextareaAutosize)
Vue.use(VueI18n)
Vue.use(VueCookie)
Vue.use(VueLogger, {
  prefix: () => new Date(),
  dev: process.env.NODE_ENV === 'development',
  shortname: true,
  levels: ['log', 'warn', 'debug', 'error', 'dir']
})

/**
 * Lang File
 */
let loadLangFile = 'de_DE.json'
let loadLangCode = 'de'

if (VueCookie.get('lang') && languages[VueCookie.get('lang')]) {
  loadLangFile = languages[VueCookie.get('lang')]
  loadLangCode = VueCookie.get('lang')
}

// Vue App
/* eslint-disable no-new */
api
  .getLang(`${loadLangFile}?t=${process.env.VUE_APP_BUILD_TIMESTAMP}`)
  .then(response => {
    const messages = {}
    messages[loadLangCode] = response.data

    // Create VueI18n instance with options
    const i18n = new VueI18n({
      locale: loadLangCode, // set locale
      messages // set locale messages
    })

    new Vue({
      // eslint-disable-line
      el: '#app',
      router,
      store,
      i18n,
      render: h => h(App)
    })
  })
  .catch(error => {
    console.error("Couldn't load language", error) // eslint-disable-line
  })
