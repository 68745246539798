export const apiURL =
  window.baseDomain && window.baseDomain.substr(0, 4) === 'http'
    ? window.baseDomain
    : `//${window.location.hostname}:8080`

export const restapi = {
  development: {
    url: apiURL,
    apikey: '44d44e765782c1676cbd7832f67cd1e5e6779e45b33a2ce4311d076cf1d13093',
    lang: `//${window.location.hostname}:3000/assets/kids/lang/`
  },
  test: {
    url: 'https://jsonplaceholder.typicode.com/',
    apikey: '44d44e765782c1676cbd7832f67cd1e5e6779e45b33a2ce4311d076cf1d13093',
    lang: `${apiURL}assets/kids/lang/`
  },
  production: {
    url: apiURL,
    apikey: '44d44e765782c1676cbd7832f67cd1e5e6779e45b33a2ce4311d076cf1d13093',
    lang: `${apiURL}assets/kids/lang/`
  }
}

export const languages = {
  de: 'de_DE.json'
}
export const mercureHubUrl = window.mercureHubUrl
