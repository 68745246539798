/* eslint-disable no-undef */
import { Model } from '@vuex-orm/core'

export default class DialogMessage extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'dialogMessages'

  static primaryKey = 'id'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      parentId: this.attr(null),
      rootId: this.attr(null),
      author: this.attr(''),
      authorId: this.attr(null),
      body: this.attr(''),
      draft: this.attr(false),
      messageType: this.attr('message'),
      read: this.attr(false),
      role: this.attr(null),
      subject: this.attr(''),
      threadCount: this.attr(''),
      timestamp: this.attr(null),
      outgoing: this.attr(null)
    }
  }
}
